<template>
  <div class="json-editor fill-height">
    <label :class="!edit ? 'fake-label ml-2' : 'label'"> {{ label }}</label>
    <pre v-if="edit" class="pt-3 black--text fill-height"> {{ json }} </pre>

    <json-editor-vue
      v-else
      v-model="json"
      :on-change="textChanged"
      :on-error="onError"
    />

    <div v-if="persistentHint" class="v-text-field__details mt-2 ml-2">
      <div class="v-messages theme--light">
        <div class="v-messages__wrapper">
          <div class="d-flex align-center v-messages__message">
            {{ hint }}

            <v-spacer />

            <v-menu v-if="!edit" offset-y>
              <template #activator="{ on, attrs }">
                <v-btn color="primary" outlined x-small v-bind="attrs" v-on="on">
                  Apply {{ label }} template
                  <v-icon class="ml-1" x-small>fal fa-angle-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in templates[label]"
                  :key="index"
                  @click="applyTemplate(item.jsonTemplate)"
                >
                  <v-list-item-title> {{ item.name }} </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonEditorVue from "json-editor-vue"

import autocadStyle from "@/modules/layers/templates/autocad-style.json"
import basicStyle from "@/modules/layers/templates/basic-style.json"
import attributeStyle from "@/modules/layers/templates/attribute-style.json"
import autocadLabel from "@/modules/layers/templates/autocad-label.json"
import basicLabel from "@/modules/layers/templates/basic-label.json"

export default {
  name: "JsonEditor",

  components: {
    JsonEditorVue,
  },

  props: {
    value: { type: [Object, Array], default: () => {} },
    edit: { type: Boolean, default: false },
    persistentHint: { type: Boolean, default: false },
    label: { type: String, default: "" },
    hint: { type: String, default: "" },
  },

  data: () => ({
    json: {},
    templates: {
      Style: [
        { name: "Basic", jsonTemplate: basicStyle },
        { name: "Attribute Based", jsonTemplate: attributeStyle },
        { name: "AutoCAD Default", jsonTemplate: autocadStyle },
      ],
      Label: [
        { name: "Basic", jsonTemplate: basicLabel },
        { name: "AutoCAD Default", jsonTemplate: autocadLabel },
      ],
    },
  }),

  watch: {
    value(newVal) {
      this.json = newVal
    },
  },

  created() {
    this.json = this.value
  },

  methods: {
    onError(err) {
      console.error(err)
    },

    applyTemplate(jsonTemplate) {
      this.$emit("input", jsonTemplate)
    },

    textChanged(inputJson) {
      try {
        //handle tree mode (json) and text mode (text)
        const inputData =
          Object.keys(inputJson).indexOf("json") !== -1
            ? inputJson.json
            : JSON.parse(inputJson.text)
        this.$emit("input", inputData)
      } catch (e) {
        console.log("Error parsing input JSON")
      }
    },
  },
}
</script>

<style scoped>
.json-editor >>> .ace_scrollbar {
  z-index: 1 !important;
}
.fake-label {
  position: absolute;
  margin-left: 15px;
  margin-top: -10px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: white;
  z-index: 1;
  font-size: small;
  color: rgba(0, 0, 0, 0.6);
}
.label {
  font-size: small;
  color: rgba(0, 0, 0, 0.6);
}
pre {
  background-color: #f5f5f5 !important;
  border-radius: 6px !important;
  padding-left: 6px;
}
/* Simplify Tree view by hiding some things */
.json-editor ::v-deep .jse-tag.jse-expanded.svelte-wovgt4,
.json-editor ::v-deep .jse-identifier.svelte-wovgt4,
.json-editor ::v-deep .jse-separator.svelte-wovgt4,
.json-editor ::v-deep .jse-group-button.jse-last.svelte-pf7s2l,
.json-editor ::v-deep .jse-navigation-bar.svelte-xs03gj {
  display: none;
}
.json-editor {
  /* define a custom theme color */
  --jse-text-color-inverse: var(--v-primary-base);
  --jse-theme-color-highlight: var(--v-primary-lighten5);
  --jse-theme-color: white;
  border-color: rgba(0, 0, 0, 0.38);
}
.json-editor ::v-deep .jse-contents.svelte-vrx1dr,
.json-editor ::v-deep .jse-contents.svelte-xt61xw {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 0px 0px 4px 4px;
  overflow: auto;
  min-height: 350px;
  max-height: 350px;
}
.json-editor ::v-deep .jse-menu.svelte-pf7s2l {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}
</style>
