// noinspection JSUnresolvedVariable

import Vue from "vue"

const AUTH = "auth"
const LOGIN_URL = `${AUTH}/login/`
const LOGOUT_URL = `${AUTH}/logout/`
const PASSWORD_CHANGE_URL = `${AUTH}/password/change/`
const PASSWORD_RESET_URL = `${AUTH}/password/reset/`
const PASSWORD_RESET_CONFIRM_URL = `${AUTH}/password/reset/confirm/`
const PROFILE_URL = `${AUTH}/user/`

const MY_PROFILE_URL = "users/me/"

const IMPERSONATE_ACQUIRE_URL = "impersonate/acquire/"
const IMPERSONATE_RELEASE_URL = "impersonate/release/"

export default {
  async changePassword(old_password, new_password1, new_password2) {
    const data = { old_password, new_password1, new_password2 }

    try {
      return await Vue.axios.post(PASSWORD_CHANGE_URL, data)
    } catch (error) {
      return error.response
    }
  },

  async login(data) {
    if (Vue.axios.defaults.headers.common["Authorization"])
      delete Vue.axios.defaults.headers.common["Authorization"]

    try {
      return await Vue.axios.post(LOGIN_URL, data)
    } catch (error) {
      return error.response
    }
  },

  async logout() {
    try {
      return await Vue.axios.post(LOGOUT_URL, null)
    } catch (error) {
      return error.response
    }
  },

  async profile() {
    try {
      return await Vue.axios.get(PROFILE_URL)
    } catch (error) {
      return error.response
    }
  },

  async reset(email) {
    if (Vue.axios.defaults.headers.common["Authorization"])
      delete Vue.axios.defaults.headers.common["Authorization"]

    try {
      return await Vue.axios.post(PASSWORD_RESET_URL, { email })
    } catch (error) {
      return error.response
    }
  },

  async resetConfirm(uid, token, new_password1, new_password2) {
    const data = { uid, token, new_password1, new_password2 }
    try {
      return await Vue.axios.post(PASSWORD_RESET_CONFIRM_URL, data)
    } catch (error) {
      return error.response
    }
  },

  async validateMapshareToken(token) {
    Vue.axios.defaults.headers.common["MapShare"] = `Token ${token}`

    try {
      return await Vue.axios.get(`mapshares/${token}/`)
    } catch (error) {
      return error.response
    }
  },

  async impersonate(user) {
    const bodyFormData = new FormData()
    bodyFormData.append("user_pk", user)

    try {
      return await Vue.axios.post(IMPERSONATE_ACQUIRE_URL, bodyFormData)
    } catch (error) {
      return error.response
    }
  },

  async releaseImpersonate() {
    try {
      return await Vue.axios.post(IMPERSONATE_RELEASE_URL)
    } catch (error) {
      return error.response
    }
  },

  async updateUser() {
    try {
      return await Vue.axios.get(MY_PROFILE_URL)
    } catch (error) {
      return error.response
    }
  },
}
