<template>
  <div style="display: contents">
    <v-btn v-if="showBtn" color="primary" small @click="$emit('input', true)">
      <v-icon class="mr-1" small> fal fa-plus-circle </v-icon>
      Create
    </v-btn>

    <v-dialog :value="value" max-width="75%" @click:outside="$emit('input', false)">
      <v-fade-transition>
        <v-card class="create-layer">
          <v-card-title class="green white--text"> Add a Layer </v-card-title>

          <v-card-text class="pt-4 pb-0 mb-0">
            <LayerForm
              v-if="value"
              v-model="layer"
              :edit="edit"
              :form-errors="errors"
              @cancel="$emit('input', false)"
            />
          </v-card-text>

          <v-card-actions class="pt-0 pb-4 mt-3">
            <v-btn color="grey" text outlined @click="cancel"> Cancel</v-btn>
            <v-spacer />
            <v-btn color="primary" @click="save()"> Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-fade-transition>
    </v-dialog>
  </div>
</template>

<script>
import { get } from "vuex-pathify"

import LayerForm from "./LayerForm"
import layerApi from "./api"
import basicStyle from "@/modules/layers/templates/basic-style.json"
import basicLabel from "@/modules/layers/templates/basic-label.json"
import TabViewPopup from "@/modules/layers/templates/Tab-view-popup.json"

export default {
  name: "LayerAdd",

  components: {
    LayerForm,
  },

  props: {
    value: { type: Boolean, default: null },
    showBtn: { type: Boolean, default: false },
    file: { type: File, default: () => {} },
  },

  data: () => ({
    layer: {
      zoom_min: 0,
      zoom_max: 21,
      bounds: [],
      style: basicStyle,
      label: basicLabel,
      popup: TabViewPopup.code,
    },
    edit: true,
    errors: null,
  }),

  computed: {
    organisationId: get("organisationId"),
  },

  methods: {
    async save() {
      if (this.layer.zoom_max < this.layer.zoom_min) {
        this.saving = false
        this.errors.zoom_max = "Maximum Zoom should be greater than Minimum Zoom"
        return
      }

      this.saving = true
      this.layer.organisation_id =
        this.organisationId || (await this.localStore.getItem("organisationId"))

      this.layer.popup_type = "vue-template"

      try {
        const response = await layerApi.editLayer(this.layer)
        this.saving = false
        if ([200, 201].includes(response.status)) {
          this.layer = {
            zoom_min: 0,
            zoom_max: 21,
          }
          this.$emit("done", response.data, this.file)
          this.$emit("input", false)
        } else {
          this.errors = response.data
          this.$emit("error", response.statusText)
        }
      } catch (e) {
        console.error(e)
      }
    },

    cancel() {
      this.$emit("input", false)
    },
  },
}
</script>
