<template>
  <v-row v-if="map && maplibre" class="fill-height" no-gutters>
    <v-col cols="12">
      <v-autocomplete
        id="search-box"
        v-model="selectedResult"
        :items="searchResults"
        :loading="searchLoading"
        :messages="searchMessages"
        :error="searchError"
        :search-input.sync="search"
        append-icon="fal fa-search"
        class="pt-5 px-4 map-search"
        clear-icon="fal fa-times"
        clearable
        hide-selected
        hide-no-data
        no-data-text="No results"
        no-filter
        placeholder="Search"
        return-object
        solo
      />

      <div
        v-if="!loading && map && layersReversed.length === 0"
        class="px-3 text-center"
      >
        Please add some layers:
        <br />
        <v-btn :to="{ name: 'Map', params: { id: map.id } }" color="primary" small>
          <v-icon class="mr-1" small>fal fa-map</v-icon>
          Edit Map
        </v-btn>
      </div>
      <div v-else-if="loading && layersReversed.length === 0">
        <v-progress-circular indeterminate size="15" width="2" />
        Loading layers...
      </div>

      <v-list class="pt-0 px-4 layers-list" dense>
        <v-list-item
          v-for="(layer, index) in layersReversed"
          :key="index"
          class="ma-0 px-2"
        >
          <v-checkbox
            v-model="layer.enabled"
            :off-icon="
              !layerVisible(layer) ? 'fal fa-eye-slash align-checkbox' : 'fal fa-square'
            "
            :on-icon="!layerVisible(layer) ? 'fal fa-eye-slash' : 'fal fa-check-square'"
            :disabled="!layerVisible(layer)"
            color="primary"
            class="shrink"
            @change="setMaps(layer)"
          />

          <div :class="layerVisible(layer) ? '' : 'grey--text'" class="mt-1">
            <span v-if="layer.name.endsWith('[BETA]')">
              {{ layer.name.slice(0, -6) }}
            </span>

            <span v-else>
              <a
                v-if="
                  ((layer.bounds && layer.bounds.length > 0) ||
                    (layer.extent && layer.extent.length > 0)) &&
                  layerVisible(layer)
                "
                @click="layerClicked(layer)"
              >
                {{ layer.name === "Background Map" ? $t(layer.name) : layer.name }}
              </a>
              <span v-else>
                {{ layer.name === "Background Map" ? $t(layer.name) : layer.name }}
              </span>
            </span>
          </div>

          <v-layout align-end justify-end class="mt-1">
            <div v-if="layer.description">
              <v-dialog
                v-if="layer.description.length > 0"
                v-model="layer.dialog"
                width="500"
                class="mt-4"
              >
                <template #activator="{ on }">
                  <v-btn color="primary" icon dark small v-on="on">
                    <v-icon style="font-size: 18px">fal fa-info-circle</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline grey lighten-2 pb-4" primary-title>
                    {{ layer.name }}
                  </v-card-title>

                  <v-card-text class="py-4">
                    {{ layer.description }}
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="layer.dialog = false">
                      {{ $t("close") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-chip
                v-if="layer.name.endsWith('[BETA]')"
                :color="layerVisible(layer) ? 'blue' : 'grey'"
                text-color="white"
                small
              >
                BETA
              </v-chip>
              <v-progress-circular
                v-if="loader.includes(layer.id) && layer.enabled && layerVisible(layer)"
                :width="3"
                size="20"
                color="grey"
                indeterminate
              />
            </div>
            <div class="v-btn--icon v-size--small">
              <v-btn
                v-if="layer.enabled || layer === activeFilesLayer"
                icon
                small
                @click="showLayerFiles(layer)"
              >
                <v-icon
                  :color="
                    ((layer.bounds && layer.bounds.length > 0) ||
                      (layer.extent && layer.extent.length > 0)) &&
                    layerVisible(layer)
                      ? 'primary'
                      : 'grey'
                  "
                  :class="
                    layer === activeFilesLayer ? 'mr-1 highlight-background' : 'mr-1'
                  "
                  small
                  >fal fa-folder</v-icon
                >
              </v-btn>
            </div>
          </v-layout>
        </v-list-item>
      </v-list>

      <v-divider />
    </v-col>

    <v-spacer />

    <!--    <v-col cols="12" class="d-flex align-end justify-center mb-2">
      <v-btn-toggle v-model="bgLayer" dense mandatory @change="setBgLayer()">
        <v-btn value="off" small text> Off </v-btn>
        <v-btn value="street" small text> Street Map </v-btn>
        <v-btn value="satellite" small text> Satellite </v-btn>
      </v-btn-toggle>
    </v-col>-->

    <v-col v-if="mapShare" cols="12" class="d-flex align-end justify-center mb-2">
      <map-share-info />
    </v-col>

    <v-col v-else cols="12" class="d-flex align-end justify-center mb-2">
      <div>
        <v-btn small block outlined class="mb-4" @click="startTour = true">
          Show Tour
        </v-btn>

        <organisation-selector
          v-if="user"
          v-show="user.organisations.length > 1"
          ref="orgSelector"
          class="mb-2"
          expand
        />
        <user-impersonate
          v-if="impersonateActive || isUserAllowed('profiles.impersonate')"
          @error="setError"
        />

        <v-divider class="mt-2" />

        <collapse v-model="drawer" class="my-1 text-center" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { debounce } from "lodash"
import { get, sync } from "vuex-pathify"

import { isUserAllowed } from "@/utils/general"

import UserImpersonate from "@/modules/users/UserImpersonate"
import Collapse from "@/components/Collapse"

import OrganisationSelector from "@/modules/organisations/OrganisationSelector"

import api from "./api"
import MapShareInfo from "./MapShareInfo"

// @vuese
// Sidebar toggle navigation on map page
// @group Map
export default {
  name: "MapSidebar",

  components: {
    Collapse,
    UserImpersonate,
    MapShareInfo,
    OrganisationSelector,
  },

  data: () => ({
    bgLayer: "street",
    search: null,
    searchError: false,
    searchLoading: false,
    searchMessages: [],
    searchResults: [],
    selectedResult: null,
    superUser: true,
  }),

  computed: {
    ...get("auth", ["user"]),
    ...get("maps", ["maplibre", "loader", "loading"]),
    ...sync("maps", ["maps", "startTour"]),
    activeFilesLayer: sync("activeFilesLayer"),
    mapLayerFilesShown: sync("mapLayerFilesShown"),

    drawer: sync("drawer"),
    impersonateActive: get("impersonateActive"),
    map: get("activeMap"),
    theme: get("layout"),

    // @vuese
    // reverse layers from map object on component ready
    layersReversed() {
      return this.map.layers?.slice().reverse() || []
    },

    mapShare() {
      return !!this.$store.get("auth/mapShare")
    },
  },

  watch: {
    // @vuese
    // Watch for search string changes to execute search method
    search(value) {
      this.searchMap(value)
    },

    // watch for selected result to pan on the map
    selectedResult(value) {
      if (!value) return

      if (value.bounds) {
        if (this.$vuetify.breakpoint.xsOnly) this.$store.set("drawer", false)
        if (value.layerId) this.showHideLayer(value.layerId, true)

        this.maplibre.fitBounds(value.bounds, { maxZoom: 17, animate: false })
      }

      this.search = null
      this.searchResults = []
      this.searchMessages = []
    },

    map: {
      async handler(map) {
        if (!map) return

        const bgLayer = await this.localStore.getItem(`bg_layer${map.id}`)
        this.bgLayer = bgLayer || map.bg_layer || "street"
      },
      immediate: true,
    },
  },

  methods: {
    isUserAllowed,

    // @vuese
    // search method to find bridges or layers on the map
    // @arg name of the bridge/layer
    searchMap: debounce(async function (value) {
      if (!value || value.length === 0) {
        this.searchResults = []
        return
      }
      this.searchLoading = true
      this.searchError = false
      this.searchMessages = []

      const mapBounds = this.maplibre.getBounds()
      // West, South, East, North
      const mapViewWSEN = [
        mapBounds._sw.lng,
        mapBounds._sw.lat,
        mapBounds._ne.lng,
        mapBounds._ne.lat,
      ]

      const data = { query: value, mapView: mapViewWSEN }

      try {
        const response = await api.searchMap(this.map.id, data)
        const query = response.config.params.query
        // Need to make sure we only save results IF this query is for our current search
        if (query === this.search) {
          this.searchResults = response.data
          if (this.searchResults.length === 0) this.searchMessages = "No results"
        }
      } catch (error) {
        this.$emit("error", error)
        this.searchMessages = error
        this.searchError = true
      }

      this.searchLoading = false
    }, 275),
    // @vuese
    // toggle layer visibility
    // @arg layer ID and current visibility status
    showHideLayer(layerId, status) {
      for (const layer of this.map.layers || []) {
        if (layer.id === layerId) {
          layer.enabled = status
          break
        }
      }
    },

    created() {
      // TODO: I think I can remove this because App.vue (root comp) calls this action
      this.$store.dispatch("loadImpersonateActive")
    },

    // @vuese
    // Get layer visibility status based on zoom level
    // @arg layer
    layerVisible(layer) {
      if (this.maplibre)
        return (
          layer.zoom_max > this.maplibre.getZoom() &&
          this.maplibre.getZoom() > layer.zoom_min
        )
      return false
    },

    // @vuese
    // Set error message while api error
    // @arg message
    setError(message) {
      this.$emit("error", message)
    },

    layerClicked(layer) {
      this.zoomTo(layer)
      this.activeFilesLayer = this.mapLayerFilesShown ? layer : undefined
    },

    zoomTo(layer) {
      let bounds = layer.bounds && layer.bounds > 0 ? layer.bounds : layer.extent

      this.maplibre.fitBounds(bounds, {
        animate: false,
        padding: { top: 100, bottom: 100, left: 100, right: 100 },
        maxZoom: 18,
      })
    },

    showLayerFiles(layer) {
      this.activeFilesLayer = layer === this.activeFilesLayer ? undefined : layer
      this.mapLayerFilesShown = layer === this.activeFilesLayer
    },

    setMaps(layer) {
      this.maps.map((map) => {
        if (map.id === this.map.id && map.uiLayerStatus) {
          map.uiLayerStatus[layer.id] = !map.uiLayerStatus[layer.id]
        }
      })
    },
  },
}
</script>

<style scoped>
.v-navigation-drawer
  >>> .v-select.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  -webkit-transform: none;
  transform: none;
}

.v-input--selection-controls {
  margin-top: 8px;
  padding-top: 0px;
  margin-bottom: -18px;
}

.v-input--selection-controls.v-input {
  flex: 1 1 auto;
}

.highlight-background {
  background: color-mix(in srgb, currentColor 20%, transparent);
  border-radius: 50%;
}
</style>
